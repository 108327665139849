<template>
  <div class="selectProducts">
    <el-tabs type="border-card" v-model="activeName">
      <el-tab-pane label="活动分销商品" name="tag_1">
        <div>
          <div class="search">
            <span>商品名称:</span>
            <el-input
              v-model="shopListData.keyword"
              placeholder="请输入活动名称"
            ></el-input>
            <span>类型:</span>
            <el-select
              @change="selectChange"
              v-model="shopListData.prodType"
              placeholder="请选择"
            >
              <el-option
                v-for="item in optionsShop"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>

            <el-button type="primary" @click="getShopList()">查找</el-button>
          </div>
          <el-table
            size="mini"
            border
            :data="shopData"
            style="width: 100%"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>

            <el-table-column
              align="center"
              prop="prodName"
              label="商品名称"
              width="300"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="prodType"
              label="商品类型"
              width="100"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="strIsHot"
              label="是否热门"
              width="100"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="strIsNew"
              label="是否新品"
              width="100"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="strIsTj"
              label="是否推荐"
              width="100"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="prodStatus"
              label="状态"
            ></el-table-column>

            <el-table-column align="center" label="操作">
              <template slot-scope="{ row }">
                <el-button type="primary" size="mini" @click="onAdd([row], 1)"
                  >确认</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleChangeShop"
            @current-change="handleCurrentShop"
            :current-page="shopListData.currentPage"
            :page-sizes="[5, 10, 15, 20]"
            :page-size="shopListData.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="shopPagination.total"
          >
          </el-pagination>
        </div>
      </el-tab-pane>
    </el-tabs>

    <div class="pb_footer">
      <el-button @click="$emit('close')">取 消</el-button>
      <el-button type="primary" @click="onAdd(multipleSelection, activeName)"
        >确 定</el-button
      >
    </div>
  </div>
</template>
<script>
import { taskListShopFxList } from "@/api/wanxiaoShop";

export default {
  name: "productBox",
  data() {
    return {
      dialogVisible: true,
      shopPagination: {},
      shopData: [],
      shopListData: {
        currentPage: 1,
        pageSize: 5,
        keyword: "",
        prodType: 1,
      },

      optionsShop: [
        {
          value: 1,
          label: "客房",
        },
        {
          value: 2,
          label: "门票",
        },
        {
          value: 3,
          label: "线路",
        },
        {
          value: 4,
          label: "万旅卡",
        },
        {
          value: 5,
          label: "精选商城",
        },
        {
          value: 8,
          label: "餐饮美食",
        },
        {
          value: 10,
          label: "超值礼包",
        },
      ],

      activeName: "tag_1",
      multipleSelection: [], // 多选项
    };
  },
  watch: {},
  created() {
    this.getShopList();
  },
  methods: {
    selectChange(val) {
      this.multipleSelection = [];
      this.shopListData.currentPage = 1;
      this.getShopList();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    async getShopList() {
      const { data } = await taskListShopFxList(this.shopListData);
      console.log(data, "qeqw1321");
      this.shopData = data.list;
      this.shopPagination = data.pagination;
    },

    async onAdd(list, strType) {
      // console.log(strType, list);
      let type = this.activeName.split("_")[1];
      if (list.length < 1) {
        return this.$message.error("请先选择产品！");
      }
      if (type == 1) {
        let newList = list.map((item) => {
          return {
            id: item.profitId,
          };
        });

        this.$emit("addData", newList, type);
      }
    },

    handleChangeShop(num) {
      this.shopListData.pageSize = num;
      this.getShopList();
    },
    handleCurrentShop(num) {
      this.shopListData.currentPage = num;
      this.getShopList();
    },
  },
};
</script>
<style lang="less" scoped>
.selectProducts {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .search {
    span {
      margin: 0 20px;
    }
    .el-input {
      width: 200px;
    }
    .el-select {
      margin: 0 20px;
    }
  }
  .line {
    .el-select {
      width: 150px;
    }
  }
  .el-table {
    margin-top: 20px;
  }
  .el-pagination {
    margin-top: 20px;
    float: right;
  }
  .pb_footer {
    margin-top: 30px;
    display: flex;
    align-items: center;
    // justify-content: flex-start;
    .el-button {
      margin-right: 20px;
    }
  }
}
</style>
