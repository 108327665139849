<template>
  <div>
    <div class="search">
      <div class="title">活动商品列表</div>
      <hr />
      <el-form :inline="true" class="top">
        <el-form-item label="商品名称:">
          <el-input v-model="params.keyword"></el-input>
        </el-form-item>
        <el-form-item label="类型:">
          <!-- 下拉框 -->
          <el-select v-model="params.prodType" placeholder="请选择">
            <el-option
              v-for="item in typeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="时间:">
          <el-date-picker
            :editable="false"
            :picker-options="pickerOptions0"
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="date"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item> -->
        <el-form-item>
          <el-button type="primary" @click="getList">查询</el-button>
          <el-button type="primary" @click="dialogVisible = true">添加</el-button>
          <el-button type="primary" @click="$router.go(-1)">返回</el-button>
          <!-- <el-button type="primary" @click="reset">重置</el-button> -->
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData"
      border
      tooltip-effect="dark"
      :row-key="rowKey"
      style="width: 100%"
      align="center"
    >
      <!-- <el-table-column type="selection" width="55" :reserve-selection="true" align="center"> </el-table-column> -->
      <el-table-column label="排序" width="120">
        <template slot-scope="{ row }">
          <el-input
            v-model="row.wDept"
            placeholder="请输入排序值"
            @change="setSort(row)"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="taskName" label="活动名称" align="center"> </el-table-column>

      <el-table-column prop="prodName" label="商品名称" width="180" align="center">
      </el-table-column>
      <el-table-column prop="strStatus" label="商品状态" width="110" align="center">
      </el-table-column>
      <el-table-column prop="isShow" label="是否展示" width="110" align="center">
      </el-table-column>
      <el-table-column prop="strIsNew" label="是否新品" width="110" align="center">
      </el-table-column>
      <el-table-column prop="strIsHot" label="是否热门" width="110" align="center">
      </el-table-column>
      <el-table-column prop="strIsTj" label="是否推荐" width="110" align="center">
      </el-table-column>
      <el-table-column prop="totalCount" label="活动总数量" width="110" align="center">
      </el-table-column>
      <el-table-column prop="quotaCount" label="限购数量" width="110" align="center">
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="remove(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <!-- <div>
              <el-button type="primary" @click="allCheckVip">批量修改vip</el-button>
            <el-button type="primary" @click="allOpe">批量操作</el-button>
         </div> -->
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="params.currentPage"
          :page-sizes="[5, 10, 20]"
          :page-size="params.pageSize"
          layout="total,sizes,prev,pager,next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="50%"
      :close-on-click-modal="false"
    >
      <productBoxTask @close="close" @addData="addData"></productBoxTask>
      <!-- 弹窗 -->
      <el-dialog title="提示" append-to-body :visible.sync="dialogVisible2" width="30%">
        <!-- 表单 -->
        <el-form
          :model="addForm"
          :rules="addFormRules"
          ref="addFormRef"
          label-width="100px"
        >
          <el-form-item label="总数量" prop="totalCount">
            <el-input v-model.number="addForm.totalCount"></el-input>
          </el-form-item>
          <el-form-item label="限购数量" prop="quotaCount">
            <el-input v-model.number="addForm.quotaCount"></el-input>
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible2 = false">取 消</el-button>
          <el-button type="primary" @click="submitForm">确 定</el-button>
        </span>
      </el-dialog>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span> -->
    </el-dialog>
  </div>
</template>

<script>
import {
  taskListShopList,
  taskListShopListAdd,
  taskListShopListDel,
  taskListShopSort,
} from "../../../api/wanxiaoShop.js";
import productBoxTask from "./productBoxTask.vue";
export default {
  components: {
    productBoxTask,
  },
  data() {
    return {
      dialogVisible: false,
      total: 0,
      params: {
        currentPage: 1,
        pageSize: 5,
        taskId: "",
        prodType: 0,
        keyword: "",
      },
      typeList: [
        {
          value: 0,
          label: "全部",
        },
        {
          value: 1,
          label: "客房",
        },
        {
          value: 2,
          label: "门票",
        },
        {
          value: 3,
          label: "线路",
        },
        {
          value: 4,
          label: "万旅卡",
        },
        {
          value: 5,
          label: "精选商城",
        },
        {
          value: 8,
          label: "餐饮美食",
        },
        {
          value: 10,
          label: "超值礼包",
        },
      ],
      dialogVisible2: false,
      addForm: {
        totalCount: "",
        quotaCount: "",
      },
      addFormRules: {
        totalCount: [{ required: true, message: "请输入总数量", trigger: "blur" }],
        quotaCount: [{ required: true, message: "请输入限购数量", trigger: "blur" }],
      },
      tableData: [],
      date: null,
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6;
        },
      },
    };
  },
  created() {
    this.params.taskId = this.$route.query.id;
    this.getList();
  },
  methods: {
    setSort(row, type) {
      const params = {
        taskProfitId: row.taskProfitId,
        dept: row.wDept,
      };
      taskListShopSort(params).then((res) => {
        this.getList();
        this.$message.success("修改成功");
      });
    },
    rowKey(row) {
      return row.userId;
    },
    async getList() {
      //   this.params.beginTime = this.date ? this.date[0] : "";
      //   this.params.endTime = this.date ? this.date[1] : "";
      const { data } = await taskListShopList(this.params);
      this.tableData = data.list;
      this.total = data.pagination.total;
    },
    handleSizeChange(e) {
      this.params.pageSize = e;
      this.getList();
    },
    // 页数改变
    handleCurrentChange(e) {
      this.params.currentPage = e;
      this.getList();
    },
    reset() {
      this.params.currentPage = 1;
      this.params.pageSize = 5;
      this.params.phone = "";
      this.params.beginTime = "";
      this.params.endTime = "";
      this.date = null;
      this.getList();
    },
    close() {
      this.dialogVisible = false;
    },
    addData(data) {
      this.selectData = data;
      this.dialogVisible2 = true;
    },
    remove(row) {
      this.$confirm("此操作将删除该商品, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await taskListShopListDel({
            taskProfitId: row.taskProfitId,
          });
          if (data.code == 0) {
            this.$message.success("删除成功");
            this.getList();
          } else {
            this.$message.error(data.msg);
          }
        })
        .catch(() => {});
    },
    submitForm() {
      this.$refs.addFormRef.validate(async (valid) => {
        if (valid) {
          if (this.load) return;
          this.load = true;
          const { data } = await taskListShopListAdd({
            ...this.addForm,
            profitIds: this.selectData.map((item) => item.id).join(","),
            taskId: this.params.taskId,
          });
          this.load = false;
          if (data.code == 0) {
            this.$message.success("添加成功");
            this.dialogVisible2 = false;
            this.getList();
          } else {
            this.$message.error(data.msg);
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.title {
  padding: 10px 0;
}
.top {
  margin-top: 20px;
}
.block {
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
</style>
